<script setup>
import { county } from "@/utils/vue_helpers";
import { computed } from "vue";
import kilifiBackgroundImage from "@/assets/images/beach.jpg";
import kirinyagaBackgroundImage from "@/assets/images/kiri_3.jpg";

const backgroundImageUrl = computed(() => {
  if (county.isKilifi) {
    return kilifiBackgroundImage;
  } else if (county.isKirinyaga) {
    return kirinyagaBackgroundImage;
  }
});
</script>

<template>
  <div
    class="pt-md-16 pb-md-6"
    :style="[
      {
        backgroundImage: `url(${backgroundImageUrl})`,
        backgroundSize: 'cover',
        height: '100%',
      },
      !$mobileBreakpoint && {},
    ]"
  >
    <slot name="content" />
  </div>
</template>
