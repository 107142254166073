var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"pt-md-16 pb-md-6",style:([
    {
      backgroundImage: `url(${_setup.backgroundImageUrl})`,
      backgroundSize: 'cover',
      height: '100%',
    },
    !_vm.$mobileBreakpoint && {},
  ])},[_vm._t("content")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }