<script setup>
import { countyIsKilifi, countyIsKirinyaga } from "@/utils/vue_helpers";
import BackgroundImage from "@/views/BackgroundImage";
import ILabLogo from "../utils/ILabLogo.vue";
import { computed } from "vue";

const title = computed(() => {
  if (countyIsKirinyaga.value) {
    return "E-Pay";
  } else if (countyIsKilifi.value) {
    return "Mwananchi Pay";
  }
});
</script>

<template>
  <BackgroundImage>
    <template #content>
      <v-container
        :class="{
          'pa-0 pa-md-3': true,
          'reduced-opacity-background': $mobileBreakpoint,
        }"
        :style="[$mobileBreakpoint && { height: '100%' }]"
      >
        <v-row justify="center" :no-gutters="$mobileBreakpoint">
          <v-col cols="12" md="12" class="">
            <v-sheet
              :rounded="!$mobileBreakpoint"
              :class="{ 'reduced-opacity-background': !$mobileBreakpoint }"
              :style="[$mobileBreakpoint && { backgroundColor: 'transparent' }]"
            >
              <v-container class="pa-0 pa-md-3">
                <v-row
                  :no-gutters="$mobileBreakpoint"
                  :class="{ 'pt-3': $mobileBreakpoint }"
                >
                  <!-- Left side -->
                  <v-col
                    :class="{
                      'rounded d-flex align-center': true,
                      'left-side-gradient': !$mobileBreakpoint,
                    }"
                    cols="12"
                    md="4"
                    :style="[
                      !$mobileBreakpoint && {
                        minHeight: '500px',
                      },
                    ]"
                  >
                    <div
                      :class="{
                        'd-flex px-3': true,
                        'px-md-6': countyIsKirinyaga,
                        'px-md-2': countyIsKilifi,
                      }"
                      :style="[
                        { width: '100%' },
                        $vuetify.breakpoint.mdAndUp && { width: '100%' },
                      ]"
                    >
                      <div
                        :class="{
                          'rounded white--text py-md-6 pl-md-4 pr-md-4 d-flex flex-column': true,
                          'py-2': $mobileBreakpoint,
                        }"
                        :style="[
                          {
                            width: '100%',
                            backgroundColor: 'rgba(255,255,255,0.5)',
                          },
                          $vuetify.breakpoint.mdAndUp && {
                            width: '100%',
                            backgroundColor: 'rgba(220,220,220,0.1)',
                          },
                        ]"
                      >
                        <div
                          :class="{
                            'd-flex align-center': true,
                            'justify-center': $mobileBreakpoint,
                          }"
                        >
                          <v-icon
                            :size="$mobileBreakpoint ? 40 : 56"
                            :class="{
                              'primary--text': $mobileBreakpoint,
                              'white--text': !$mobileBreakpoint,
                            }"
                            >mdi-web</v-icon
                          ><span
                            :class="{
                              'ml-md-2 font-weight-bold': true,
                              'text-h2':
                                $vuetify.breakpoint.lgAndUp &&
                                countyIsKirinyaga,
                              'text-h3':
                                $vuetify.breakpoint.lgAndUp && countyIsKilifi,
                              'text-h4': $vuetify.breakpoint.md,
                              'primary--text text-h6': $mobileBreakpoint,
                            }"
                            >{{ title }}</span
                          >
                        </div>
                        <div
                          :class="{
                            'text-h5 mt-md-10': true,
                            'primary--text text-center text-body-1':
                              $mobileBreakpoint,
                            'text-center': countyIsKilifi,
                          }"
                        >
                          Access county services conveniently online
                        </div>

                        <div
                          v-if="countyIsKirinyaga"
                          :class="{
                            'text-h6 mt-2 mt-md-10': true,
                            'primary--text text-center text-body-1':
                              $mobileBreakpoint,
                          }"
                        >
                          Simple, Secure, Convenient
                        </div>

                        <div
                          v-if="countyIsKilifi"
                          :class="{
                            'align-self-center': true,
                            'mt-4': $mobileBreakpoint,
                            'mt-10': $vuetify.breakpoint.mdAndUp,
                          }"
                          :style="{ width: '50%' }"
                        >
                          <ILabLogo />
                        </div>
                      </div>
                    </div>
                  </v-col>

                  <v-col v-if="$mobileBreakpoint" class="mt-4" cols="12">
                    <v-divider />
                  </v-col>

                  <!-- Right side -->
                  <v-col
                    class="d-flex flex-column justify-center px-0"
                    cols="12"
                    md="8"
                  >
                    <div>
                      <transition name="fade" mode="out-in">
                        <router-view> </router-view>
                      </transition>
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-sheet>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </BackgroundImage>
</template>

<style scoped>
.left-side-gradient {
  background: linear-gradient(
    145deg,
    var(--v-primary-base) 0,
    var(--v-primary-lighten2) 50%,
    var(--v-secondary-lighten2) 100%
  );
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease-out;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
