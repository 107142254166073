var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_setup.BackgroundImage,{scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('v-container',{class:{
        'pa-0 pa-md-3': true,
        'reduced-opacity-background': _vm.$mobileBreakpoint,
      },style:([_vm.$mobileBreakpoint && { height: '100%' }])},[_c('v-row',{attrs:{"justify":"center","no-gutters":_vm.$mobileBreakpoint}},[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-sheet',{class:{ 'reduced-opacity-background': !_vm.$mobileBreakpoint },style:([_vm.$mobileBreakpoint && { backgroundColor: 'transparent' }]),attrs:{"rounded":!_vm.$mobileBreakpoint}},[_c('v-container',{staticClass:"pa-0 pa-md-3"},[_c('v-row',{class:{ 'pt-3': _vm.$mobileBreakpoint },attrs:{"no-gutters":_vm.$mobileBreakpoint}},[_c('v-col',{class:{
                    'rounded d-flex align-center': true,
                    'left-side-gradient': !_vm.$mobileBreakpoint,
                  },style:([
                    !_vm.$mobileBreakpoint && {
                      minHeight: '500px',
                    },
                  ]),attrs:{"cols":"12","md":"4"}},[_c('div',{class:{
                      'd-flex px-3': true,
                      'px-md-6': _setup.countyIsKirinyaga,
                      'px-md-2': _setup.countyIsKilifi,
                    },style:([
                      { width: '100%' },
                      _vm.$vuetify.breakpoint.mdAndUp && { width: '100%' },
                    ])},[_c('div',{class:{
                        'rounded white--text py-md-6 pl-md-4 pr-md-4 d-flex flex-column': true,
                        'py-2': _vm.$mobileBreakpoint,
                      },style:([
                        {
                          width: '100%',
                          backgroundColor: 'rgba(255,255,255,0.5)',
                        },
                        _vm.$vuetify.breakpoint.mdAndUp && {
                          width: '100%',
                          backgroundColor: 'rgba(220,220,220,0.1)',
                        },
                      ])},[_c('div',{class:{
                          'd-flex align-center': true,
                          'justify-center': _vm.$mobileBreakpoint,
                        }},[_c('v-icon',{class:{
                            'primary--text': _vm.$mobileBreakpoint,
                            'white--text': !_vm.$mobileBreakpoint,
                          },attrs:{"size":_vm.$mobileBreakpoint ? 40 : 56}},[_vm._v("mdi-web")]),_c('span',{class:{
                            'ml-md-2 font-weight-bold': true,
                            'text-h2':
                              _vm.$vuetify.breakpoint.lgAndUp &&
                              _setup.countyIsKirinyaga,
                            'text-h3':
                              _vm.$vuetify.breakpoint.lgAndUp && _setup.countyIsKilifi,
                            'text-h4': _vm.$vuetify.breakpoint.md,
                            'primary--text text-h6': _vm.$mobileBreakpoint,
                          }},[_vm._v(_vm._s(_setup.title))])],1),_c('div',{class:{
                          'text-h5 mt-md-10': true,
                          'primary--text text-center text-body-1':
                            _vm.$mobileBreakpoint,
                          'text-center': _setup.countyIsKilifi,
                        }},[_vm._v(" Access county services conveniently online ")]),(_setup.countyIsKirinyaga)?_c('div',{class:{
                          'text-h6 mt-2 mt-md-10': true,
                          'primary--text text-center text-body-1':
                            _vm.$mobileBreakpoint,
                        }},[_vm._v(" Simple, Secure, Convenient ")]):_vm._e(),(_setup.countyIsKilifi)?_c('div',{class:{
                          'align-self-center': true,
                          'mt-4': _vm.$mobileBreakpoint,
                          'mt-10': _vm.$vuetify.breakpoint.mdAndUp,
                        },style:({ width: '50%' })},[_c(_setup.ILabLogo)],1):_vm._e()])])]),(_vm.$mobileBreakpoint)?_c('v-col',{staticClass:"mt-4",attrs:{"cols":"12"}},[_c('v-divider')],1):_vm._e(),_c('v-col',{staticClass:"d-flex flex-column justify-center px-0",attrs:{"cols":"12","md":"8"}},[_c('div',[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[_c('router-view')],1)],1)])],1)],1)],1)],1)],1)],1)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }